import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../auth/http.service';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
@HostListener('window:resize', ['$event'])
export class SideNavComponent implements OnInit {

  // tslint:disable-next-line: variable-name
  _opened = false;
  width: number = window.innerWidth;
  slideType = 'slide';
  mobileDevice = true;
  username = localStorage.getItem('username');
  role = localStorage.getItem('role')?.split('_').join(' ');
  companyName = localStorage.getItem('companyName');
  profileImage = localStorage.getItem('profileImage');
  loadingImage: boolean = true;
  imageSource: string;


  constructor(
    private authService: AuthService,
    public httpService: HttpService,
  ) { }

  ngOnInit(): void {

    this.getUserData();
    if (this.width > 768) {
      this.slideType = 'push';
      this.mobileDevice = false;
      this._opened = true;
    }
  }

  getUserData() {
    this.httpService.getUserDetails().subscribe(results => {
      this.httpService.userData = results;
      if (this.httpService.userData && this.httpService.userData.profileImage) {
        this.httpService.userData.profileImage = this.displayReport(this.httpService.userData.profileImage);


      }
    });
  }
  displayReport(profileImage) {


    const responseType = { responseType: 'JSON' };

    this.httpService.getFile(profileImage, responseType).subscribe(data => {

      return this.httpService.userData.profileImage = `data:image/png;base64,` + data;
    },
      error => {

        console.log(error);
      });


  }
  _toggleSidebar() {
    this._opened = !this._opened;
  }

  logout() {
    localStorage.clear();
    this.authService.logout();
  }



}
