import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { EMPTY, BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public showButton = new BehaviorSubject<boolean>(false);
  public showForgotButton = new BehaviorSubject<boolean>(false);
  public showResetButton = new BehaviorSubject<boolean>(false);

  tokenTimer: any;
  private accessToken: string;
  private isAuthenticated = false;
  private errorMessage: string;
  private role;

  constructor(

    private router: Router,
    private spinner: NgxSpinnerService,
    private httpService: HttpService) { }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getToken() {
    return this.accessToken;
  }
  getRole() {
    return this.role;
  }

  // Login method: call getAccessToken to get Authenticated. Later attach the token to /users to get user details
  login(username: string, password: string) {
    const user = new FormData();
    user.append('username', username);
    user.append('password', password);
    user.append('client_id', 'rg');
    user.append('client_secret', 'test1234');
    user.append('grant_type', 'password');
    // service to send login details for authentication
    this.httpService.userLogin(user).pipe(
      switchMap((response) => {
        const token = response.access_token;
        this.accessToken = token;
        if (token) {
          const expiresInDuration = +response.expires_in;
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true;
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          console.log(expirationDate);
          this.saveAuthData(token, expirationDate, response.refresh_token);
          return this.httpService.getUserDetails(); // service to get user details after login
        } else {
          alert('error: Not authorized');
          return EMPTY;
        }
      })
    ).subscribe(
      (response) => {
        this.spinner.hide();
        this.role = response.role;
        if ((response.msg === 'Success' && response.category === 'DPIRD') || (response.msg === 'Success' && response.category === 'ODC')) {
          this.role = response.role;
          console.log(response);
          localStorage.setItem('username', response.username);
          localStorage.setItem('role', this.role);
          localStorage.setItem('companyName', response.companyName);
          localStorage.setItem('profileImage', response.profileImage);
          localStorage.setItem('firstLogin', response.firstLogin);
          localStorage.setItem('registration', response.companyRegt);
          localStorage.setItem('userData', JSON.stringify(response));
          this.httpService.userData = response;

          if (this.httpService.userData && this.httpService.userData.profileImage) {
            this.httpService.userData.profileImage = this.displayReport(this.httpService.userData.profileImage);

          }

          Swal.fire({
            title: response.msg,
            icon: 'success',
            timer: 800,
            showCancelButton: false,
            showConfirmButton: false
          }).then(() => {
            if (response.firstLogin) {
              this.router.navigate(['/change-password']);
            }
            if (!response.firstLogin && response.companyRegt == false) {
              this.router.navigate(['/company-registration']);
            }
            if ((response.category === 'DPIRD' && !response.firstLogin && response.companyRegt == true)
              || (response.category === 'ODC' && !response.firstLogin && response.companyRegt == true)) {
              this.router.navigate(['/dashboard']);
            }
          });
        }
        // tslint:disable-next-line: max-line-length
        else {
          Swal.fire('Error!', 'Unauthorized Access', 'error');
          this.spinner.hide();
          localStorage.clear();
          this.router.navigate(['/sign-in']);
        }
      });
  }

  displayReport(profileImage) {
    const responseType = { responseType: 'JSON' };
    this.httpService.getFile(profileImage, responseType).subscribe(data => {
      return this.httpService.userData.profileImage = `data:image/png;base64,` + data;
    },
      error => {
        console.log(error);
      });
  }


  // call refreshToken after expiry of accessToken
  refreshToken(): void {
    const refreshToken = localStorage.getItem('refreshToken');
    console.log(refreshToken);
    const refreshUser = new FormData();
    refreshUser.append('grant_type', 'refresh_token');
    refreshUser.append('refresh_token', refreshToken);
    refreshUser.append('client_id', 'rg');
    refreshUser.append('client_secret', 'test1234');
    console.log(refreshUser);
    // service to refresh token
    this.httpService.refreshToken(refreshUser)
      .subscribe(response => {
        console.log(response);
        const token = response.access_token;
        this.accessToken = token;
        if (token) {
          const expiresInDuration = +response.expires_in;
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true;
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          console.log(expirationDate);
          this.saveAuthData(token, expirationDate, response.refresh_token);
        }
      });
  }


  // to save auth data in localStorage after login
  private saveAuthData(accessToken: string, expirationDate: Date, refreshToken: string): void {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('expiresIn', expirationDate.toISOString());
    localStorage.setItem('refreshToken', refreshToken);

  }

  // function to get AuthData from localStorage
  private getAuthData() {
    const accessToken = localStorage.getItem('accessToken');
    const expirationDate = localStorage.getItem('expiresIn');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!accessToken || !expirationDate || !refreshToken) {
      return;
    }
    return {
      accessToken,
      expirationDate: new Date(expirationDate),
      refreshToken,
    };
  }

  // auto auth user if token exists on initial load of app.component
  autoAuthUser(): void {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    console.log(authInformation, expiresIn);
    if (expiresIn > 0) {
      this.accessToken = authInformation.accessToken;
      this.setAuthTimer(expiresIn / 1000);
      this.isAuthenticated = true;
    }
  }

  // timer function to call refreshToken after expiry of accessToken
  private setAuthTimer(duration: number): void {
    console.log('Setting timer: ' + duration);
    this.tokenTimer = setTimeout(() => {
      this.refreshToken();
    }, duration * 1000);
  }

  changePassword(oldPassword: string, newPassword: string) {
    const password = {
      oldPassword,
      newPassword
    };
    this.httpService.updatePassword(password).subscribe(response => {
      if (response.status) {
        localStorage.setItem('firstLogin', 'false');
      }
      console.log(JSON.parse(localStorage.getItem('userData')).companyRegt);
      if (response.status === true && localStorage.getItem('registration') == 'true') {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'Ok',

        }).then(() => {
          this.router.navigate(['/dashboard']);
        });
      }
      if (response.status === true && localStorage.getItem('registration') == 'false') {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'Ok',

        }).then(() => {
          this.router.navigate(['/company-registration']);
        });
      }
      if (response.status === false) {
        this.showButton.next(true);
        Swal.fire('Error!', response.message, 'error');
      }
    });
    return this.showButton.asObservable();
  }

  forgotPassword(formData) {

    this.httpService.forgotPassword(formData).subscribe(response => {
      console.log(response);
      if (response.message === 'Success') {
        Swal.fire({
          title: response.msg,
          icon: 'success',
          text: `OTP has been sent to your email: ${response.email} to reset the password`,
          showCancelButton: false,
          showConfirmButton: true
        }).then(() => {
          this.router.navigate(['/reset-password'])
        });
      }
      else {
        this.showForgotButton.next(true);
        Swal.fire('Error!', response.message, 'error');
      }


    });
    return this.showForgotButton.asObservable();
  }

  resetOldPassword(email: string, otp: string, password: string) {
    const resetOldPassword = {
      email,
      otp,
      newPassword: password
    };
    this.httpService.resetPassword(resetOldPassword).subscribe(response => {
      if (response.status === true) {
        Swal.fire({
          title: response.msg,
          icon: 'success',
          text: `You will be navigated to login page`,
          showCancelButton: false,
          showConfirmButton: true
        }).then(() => {
          this.router.navigate(['/sign-in']);
        });
      }
      else {
        this.showResetButton.next(true);
        Swal.fire('Error!', response.message, 'error');
      }
    });
    return this.showResetButton.asObservable();
  }



  logout(): void {
    this.httpService.logout().subscribe(res => {
      console.log(res.status);
      if (res.status === 200) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('expiresIn');
        localStorage.removeItem('refreshToken');
        this.isAuthenticated = false;
        clearTimeout(this.tokenTimer);
        this.router.navigate(['/sign-in']);
      }
      else {
        Swal.fire('Error!', '', 'error');

      }
    });
  }
  logoutAfterExpiry(): void {

    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('refreshToken');
    this.isAuthenticated = false;
    clearTimeout(this.tokenTimer);
    this.router.navigate(['/sign-in']);

  }

}