import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { empty, Observable, EMPTY } from 'rxjs';
import { apiEndPoints } from 'src/constants/api-constants';
import {  retryWhen, delay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class HttpService {
  userData: any;
  url = environment.serverUrl;
  apiEndPoints = apiEndPoints;

  constructor(

    private http: HttpClient,
  ) { }

  // post login details for authentication
  userLogin(user) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.getAccessToken}`, user);
  }
  // get user details after authentication
  getUserDetails() {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.user}`).pipe(
      retryWhen(errors => errors.pipe(delay(10000), take(10)))
      );

  }

  // call refreshToken after accessToken expiry
  refreshToken(refreshUser) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.getAccessToken}`, refreshUser);
  }
  // method to call seedInventory
  getSeedInventory() {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getSeedInventory}`);
  }
  createRegulator(regCompanyDetails) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.createRegulator}`, regCompanyDetails);

  }

  // method to call farmLocations
  getFarmLocationsForRegulators() {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getFarmLocationsForRegulators}`);
  }

  forgotPassword(username) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.forgotPassword}`, username);

  }

  resetPassword(password) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.resetPassword}`, password);

  }

  // method to call licenses count
  getLicenses(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getLicensesCount}`);
  }

  // method to call total cultivation area for n years
  getCultivationByYear(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getCultivationByYear}`);
  }

  // method to call open notifications for sowing

  getSowingOpenNotifications(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getSowingOpenNotifications}`).pipe(
      retryWhen(errors => errors.pipe(delay(10000), take(10)))
      );

  }

  updatePassword(passwordReset) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.changePassword}`, passwordReset);

  }

  getSowingClosedNotifications(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getSowingClosedNotifications}`);
  }

  getFloweringOpenNotifications(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getFloweringOpenNotifications}`);
  }

  getFloweringClosedNotifications(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getFloweringClosedNotifications}`);
  }

  getHarvestingOpenNotifications(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getHarvestingOpenNotifications}`);
  }

  getHarvestingClosedNotifications(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getHarvestingClosedNotifications}`);
  }

  getGerminationClosedReportsSamples() {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getGerminationClosedReportsSamples}`);
  }

  getFloweringClosedReportsSamples(): Observable<any> {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.getFloweringClosedReportsSamples}`);
  }

  getFile(sampleReport, responseType): Observable<any> {
    return this.http.get(`${this.url}${this.apiEndPoints.getFile}/${sampleReport}`, responseType).pipe(
      retryWhen(errors => errors.pipe(delay(10000), take(10)))
      );


  }


  updateNotification(addComments): Observable<any> {
    return this.http.put<any>(`${this.url}${this.apiEndPoints.updateNotification}`, addComments).pipe(
      retryWhen(errors => errors.pipe(delay(10000), take(10)))
      );
  }

  // update profile
  updateProfile(obj) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.updateProfile}`, obj);
  }

  // method to report a problem
  reportProblem(obj) {
    return this.http.post<any>(`${this.url}${this.apiEndPoints.reportProblem}`, obj);

  }

  logout() {
    return this.http.get<any>(`${this.url}${this.apiEndPoints.logout}`, { observe: 'response' });

  }



}
