<div class="bg d-flex flex-column min-vh-100">
    <div class="login-form-holder my-auto">
      <div class="text-center mb-3">
        <img src="../../../assets/images/logo.png" class="rounded mx-auto d-block" alt="" routerLink="/"
          style="cursor: pointer;">
      </div>


      <div class="card card-round col-sm-12 ">
        <h3 class="mb-2 text-muted  mx-auto"> <span class="badge badge-warning rounded-0 text-dark">Forgot Password</span>
        </h3>
        <div class="card-body">
          <form [formGroup]="userForm">

            <!-- <p>
              Enter your {{isShowFiled === 'email' ? 'email address' : 'username'}} and we will send instructions on
              how to change {{isShowFiled === 'email' ? 'password' : 'username'}}
            </p> -->
            <div class="form-row">
              <div class="form-group col">
                <div *ngIf=" fieldTextType">
                  <label>Username</label>
                  <input class="form-control" type="text" formControlName="userName"
                    [class.invalid]="!userForm.controls['userName'].valid && userForm.controls['userName'].touched">
                  <div
                    *ngIf="!userForm.controls['userName'].valid && (userForm.controls['userName'].touched || isSubmitted)">
                    <div class="invalid-feedback" style="display: block;">Please enter Username</div>
                  </div>
                </div>
                <div *ngIf="!fieldTextType">
                  <label>Email</label>
                  <input class="form-control" type="text" formControlName="userEmail"
                    [class.invalid]="!userForm.controls['userEmail'].valid && userForm.controls['userEmail'].touched">
                  <div
                    *ngIf="!userForm.controls['userEmail'].valid && (userForm.controls['userEmail'].touched || isSubmitted)">
                    <div class="invalid-feedback" style="display: block;">Please enter email</div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-success mr-1" (click)="goToChangePassword()" *ngIf="show">Send</button>
            <div class="text-center mt-3" *ngIf="show">
              <a style="cursor:pointer;" (click)="toggleFieldTextType()"> Click here to reset Password
                by {{fieldTextType === false ? 'Username' : 'Email'}}</a><br>
            </div>
            <div class="text-center mt-3" *ngIf="!submitLoader">
              <div class="alert alert-info" role="alert">Please wait... </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="copyright py-2 text-center text-white">
      <div class="container"><small>© 2020 <a href="https://natsoftconsulting.com.au/" target="_blank">Natsoft
            Consulting</a> - All Rights Reserved</small></div>
    </div>

  </div>