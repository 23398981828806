<!-- Container for sidebar(s) + page content -->
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner> -->

<ng-sidebar-container style="height: 100vh;" id="loader">

  <!-- A sidebar -->
  <ng-sidebar [(opened)]="_opened" position="left" mode="{{slideType}}">
    <aside class="sidebar shadow-sm">
      <div class="sidebar-content">
        <div class="sidebar-profile  d-flex flex-column justify-content-center pt-1 pb-4">

          <div class="m-auto pl-2 pr-2 pb-4">
            <img src="../../../assets/images/logo-white.png" class="img-fluid" alt="">
          </div>

          <div class="picture-wrapper mb-4">
            <div class="user-picture m-auto">
              <label class="hoverable" *ngIf="httpService.userData && httpService.userData">

                <img [src]="httpService.userData.profileImage" class="img-fluid" onerror="this.src='../../../assets/images/user.png'" alt="" routerLink="/update-profile">
                <div class="hover-text" routerLink="/update-profile"><i class="fa fa-pencil text-dark"></i></div>
                <div class="background"></div>
              </label>

            </div>
          </div>

          <!-- <div class="profile-details text-center pb-1">
            <p class="mb-0 text-uppercase name">Welcome</p>
            <small class="text-uppercase role">Administrator</small>
          </div> -->

          <div class="profile-details text-center pb-1">
            <p class="mb-0 name">Welcome <span class="text-uppercase">{{username}} !</span>
            </p>

            <small class="text-uppercase role" style="font-size: 15px;">{{role}}</small>
            <br>
            <!-- Temporarily hardcoded company name -->
            <small class="text-uppercase role">DPIRD</small>
          </div>
        </div>
        <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
          <input type="search" class="form-control rounded-pill" name="search" placeholder="Search ...">
        </div>
        <nav class="sidebar-menu pt-3 pb-3">
          <!-- <div class="menu-header mt-3 mb-2">
            Regulatory
          </div> -->
          <ul class="menu-items" *ngIf="mobileDevice">
            <li>
              <a (click)="_toggleSidebar()" routerLink="/dashboard" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-tachometer"></i>
                <span>Dashboard</span>
              </a>
            </li>
            <!-- <div class="menu-header mt-3 mb-2">
              Notifications
            </div> -->
            <li>
              <a (click)="_toggleSidebar()" routerLink="/sowing" routerLinkActive="active">
                <i class="fa fa-eercast" aria-hidden="true"></i>
                <span>Sowing Notifications</span>
              </a>
            </li>
            <li>
              <a (click)="_toggleSidebar()" routerLink="/flowering" routerLinkActive="active">
                <i class="fa fa-leaf"></i>
                <span>Flowering Notifications</span>
              </a>
            </li>
            <li>
              <a (click)="_toggleSidebar()" routerLink="/harvesting" routerLinkActive="active">
                <i class="fa fa-braille" aria-hidden="true"></i>
                <span style="margin-left:12px!important">Harvesting Notifications</span>
              </a>
            </li>
            <!-- <li>
            <a (click)="_toggleSidebar()" routerLink="/inspection" routerLinkActive="active">
              <i class="fa fa-search-plus" aria-hidden="true"></i>
              <span>Inspection</span>
            </a>
          </li> -->
            <li>
              <a (click)="_toggleSidebar()" routerLink="/test-results" routerLinkActive="active">
                <i class="fa fa-pencil-square"></i>
                <span>Test Results</span>
              </a>
            </li>

            <li>
              <a (click)="_toggleSidebar()" routerLink="/change-password" routerLinkActive="active">
                <i class="fa fa-unlock-alt" aria-hidden="true" style="width: 16px;color: rgb(201, 200, 200);"></i>
                <span>Change Password</span>
              </a>
            </li>

            <li>
              <a (click)="_toggleSidebar()" routerLink="/report-problem" routerLinkActive="active">
                <i class="fa fa-bug" aria-hidden="true" style="width: 16px;color: rgb(201, 200, 200);"></i>
                <span>Report Problem</span>
              </a>
            </li>

            <li>
              <a (click)="logout()">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                <span>Logout</span>
              </a>
            </li>

          </ul>

          <ul class="menu-items" *ngIf="!mobileDevice">
            <li>
              <a routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <i class="fa fa-tachometer"></i>
                <span>Dashboard</span>
              </a>
            </li>
            <!-- <div class="menu-header mt-3 mb-2">
              Notifications
            </div> -->
            <li>
              <a routerLink="/sowing" routerLinkActive="active">
                <i class="fa fa-eercast" aria-hidden="true"></i>
                <span>Sowing Notifications</span>
              </a>
            </li>
            <li>
              <a routerLink="/flowering" routerLinkActive="active">
                <i class="fa fa-leaf"></i>
                <span>Flowering Notifications</span>
              </a>
            </li>
            <li>
              <a routerLink="/harvesting" routerLinkActive="active">
                <i class="fa fa-braille" aria-hidden="true"></i>
                <span style="margin-left:12px!important">Harvesting Notifications</span>
              </a>
            </li>
            <!-- <li>
            <a routerLink="/inspection" routerLinkActive="active">
              <i class="fa fa-search-plus" aria-hidden="true"></i>
              <span>Inspection</span>
            </a>
          </li> -->
            <li>
              <a routerLink="/test-results" routerLinkActive="active">
                <i class="fa fa-pencil-square"></i>
                <span>Test Results</span>
              </a>
            </li>

            <li>
              <a  routerLink="/change-password" routerLinkActive="active">
                <i class="fa fa-unlock-alt" aria-hidden="true" style="width: 16px;color: rgb(201, 200, 200);"></i>
                <span>Change Password</span>
              </a>
            </li>

            <li>
              <a  routerLink="/report-problem" routerLinkActive="active">
                <i class="fa fa-bug" aria-hidden="true" style="width: 16px;color: rgb(201, 200, 200);"></i>
                <span>Report Problem</span>
              </a>
            </li>

            <li>
              <a (click)="logout()" routerLinkActive="active" style="cursor: pointer;">
                <i class="fa fa-sign-out"></i>
                <span>Logout</span>
              </a>
            </li>

          </ul>


        </nav>

      </div>
    </aside>
  </ng-sidebar>
  <!-- Page content -->
  <div ng-sidebar-content>
    <div class="hamburger-icon text-light toggle-button ml-2" (click)="_toggleSidebar()">
      <i class="fa fa-bars" aria-hidden="true"></i>
    </div>

    <!-- <button (click)="logout()" class="ml-auto btn btn-secondary" type="button">logout</button> -->
    <router-outlet></router-outlet>
  </div>

</ng-sidebar-container>