import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  model: any = {};
  isLoading = this.spinner.hide();
  fieldTextType: boolean;
  isCollapsed = true;
  farmer = environment.farmer;
  testingAgency = environment.testingAgency;

  constructor(
    public authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    if (localStorage.getItem('accessToken') && localStorage.getItem('registration') == 'true') {
      this.router.navigate(['/']);
     
    }
    if (localStorage.getItem('registration') == 'false' && localStorage.getItem('firstLogin') == 'false') {
      this.router.navigate(['/company-registration']);

    }
  }
  onSignIn(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.isLoading = this.spinner.show();
    console.log(form.value);
    this.authService.login(form.value.username, form.value.password);
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }





}


