import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  model: any = {};
  fieldTextType: boolean;
  fieldTextType1: boolean;
  show: boolean = true;
  submitLoader: boolean = true;
  constructor(private authService: AuthService, private router: Router) {
 
  }

  ngOnInit(): void {
  }
  onChangePassword(form: NgForm) {
    if (form.invalid) {
      return;
    }
    console.log(form.value);
    this.show = false;
    this.submitLoader = false;
    this.authService.changePassword(form.value.oldPassword, form.value.password).subscribe(res => {
      this.show = res;

      if (this.show) {
        this.submitLoader = true;
        localStorage.setItem('firstLogin', 'false');

      }

    });


  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
}