export const apiEndPoints = {
    getAccessToken: 'oauth2/token',
    user: 'user',
    getSeedInventory: 'getAllSeedInventory2',
    createRegulator: 'admin/createRegulator',
    getFarmLocationsForRegulators: 'getFarmLocationsForRegulators',
    getLicensesCount: 'getGrowLicensesCount',
    getCultivationByYear: 'getCultivationByYear',
    getSowingOpenNotifications: 'getAllOpenSowingRequests',
    getSowingClosedNotifications: 'getAllClosedSowingRequests',
    getFloweringOpenNotifications: 'getAllOpenFloweringRequests',
    getFloweringClosedNotifications: 'getAllClosedFloweringRequests',
    getHarvestingOpenNotifications: 'getAllOpenHarvestingRequests',
    getHarvestingClosedNotifications: 'getAllClosedHarvestingRequests',
    getGerminationClosedReportsSamples: 'getAllClosedGerminationTestReports',
    getFloweringClosedReportsSamples: 'getAllClosedFloweringTestReports',
    getFile: 'getFile',
    updateNotification: 'updateNotificationByRG',
    changePassword: 'changePassword',
    updateProfile: 'updateUser',
    logout: 'logout1',
    forgotPassword: 'forgotPassword',
    resetPassword: 'resetPassword',
    reportProblem: 'reportProblem'


};