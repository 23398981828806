<div class=" bg d-flex flex-column min-vh-100">

  <ngx-spinner *ngIf="isLoading" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale-pulse-out"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="login-form-holder my-auto">
    <div class="text-center mb-3">
      <img src="../../../assets/images/logo.png" class="rounded mx-auto d-block" alt="" routerLink="/"
        style="cursor: pointer;">
    </div>
    <div class="card col-sm-12 ">
      <div class="card-body">

        <form name="form" (submit)="loginForm.form.valid && onSignIn(loginForm)" #loginForm="ngForm" novalidate
          *ngIf="isLoading">
          <div class="form-row">
            <div class="form-group col">
              <label>Username</label>
              <input type="text" name="username" class="form-control" [(ngModel)]="model.username" #username="ngModel"
                [ngClass]="{ 'is-invalid': loginForm.submitted && username.invalid }" required>
              <div *ngIf="loginForm.submitted && username.invalid" class="invalid-feedback">
                <div *ngIf="username.errors.required">username is required</div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col">
              <label>Password</label>
              <div class="input-group  ml-auto">
                <input [type]="fieldTextType ? 'text' : 'password'" name="password" class="form-control"
                  [(ngModel)]="model.password" #password="ngModel"
                  [ngClass]="{ 'is-invalid': loginForm.submitted && password.invalid }" required>

                <span class="input-group-append" (click)="toggleFieldTextType()">
                  <div class="input-group-text bg-transparent"> <i class="fa" [ngClass]="{
                      'fa-eye-slash': !fieldTextType,
                      'fa-eye': fieldTextType
                    }"></i>
                  </div>
                </span>

                <div *ngIf="loginForm.submitted && password.invalid" class="invalid-feedback">
                  <div *ngIf="password.errors.required">Password is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-3">
            <button class="btn btn-success mr-1" type="submit">Login</button>
          </div>

          <div class="text-center mt-3">
            <a routerLink="/forgot-password">Forgot Password?</a>
          </div>
        </form>

      </div>
    </div>
  </div>
  <div class="copyright py-2 text-center text-white">
    <div class="container"><small>© 2020 <a href="https://natsoftconsulting.com.au/" target="_blank">Natsoft
          Consulting</a> - All Rights Reserved</small></div>
  </div>
</div>