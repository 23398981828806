<div class="bg d-flex flex-column min-vh-100">
    <div class="login-form-holder my-auto">
        <div class="text-center mb-3">
            <img src="../../../assets/images/logo.png" class="rounded mx-auto d-block" alt="" routerLink="/"
                style="cursor: pointer;">
        </div>

        <div class="card card-round col-sm-12 ">
            <h3 class="mb-2 text-muted  mx-auto"> <span class="badge badge-warning rounded-0 text-dark">Change
                    Password</span>
            </h3>

            <div class="card-body">
                <form name="form" (submit)="changePassword.form.valid && onChangePassword(changePassword)"
                    #changePassword="ngForm" [appMustMatch]="['password', 'confirmPassword']" novalidate>

                    <div class="form-row">
                        <div class="form-group col">
                            <label>Current Password</label>
                            <input type="password" name="oldPassword" class="form-control"
                                [(ngModel)]="model.oldPassword" #oldPassword="ngModel"
                                [ngClass]="{ 'is-invalid': changePassword.submitted && oldPassword.invalid }" required>
                            <div *ngIf="changePassword.submitted && oldPassword.invalid" class="invalid-feedback">
                                <div *ngIf="oldPassword.errors.required">Old Password is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label>New Password</label>
                            <div class="input-group  ml-auto">
                                <input [type]="fieldTextType ? 'text' : 'password'" name="password" class="form-control"
                                    [(ngModel)]="model.password" #password="ngModel"
                                    [ngClass]="{ 'is-invalid':  (changePassword.submitted || changePassword.touched) && password.invalid }"   minlength="6" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$" required>
                                <span class="input-group-append" (click)="toggleFieldTextType()">
                                    <div class="input-group-text bg-transparent"> <i class="fa" [ngClass]="{
                                      'fa-eye-slash': !fieldTextType,
                                      'fa-eye': fieldTextType
                                    }"></i>
                                    </div>
                                </span>

                                <div *ngIf=" (changePassword.submitted || changePassword.touched) && password.invalid" class="invalid-feedback">
                                    <div *ngIf="password.errors.required">Password is required</div>
                                    <div *ngIf="password.errors.minlength || password.errors.pattern">Password must be
                                        at Minimum six characters, at least one uppercase letter, one lowercase letter,
                                        one number and one special character: </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label>Confirm New Password</label>
                            <div class="input-group  ml-auto">
                                <input [type]="fieldTextType1 ? 'text' : 'password'" name="confirmPassword"
                                    class="form-control" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel"
                                    [ngClass]="{ 'is-invalid': changePassword.submitted && confirmPassword.invalid }"
                                  required>
                                <span class="input-group-append" (click)="toggleFieldTextType1()">
                                    <div class="input-group-text bg-transparent"> <i class="fa" [ngClass]="{
                                      'fa-eye-slash': !fieldTextType1,
                                      'fa-eye': fieldTextType1
                                    }"></i>
                                    </div>
                                </span>
                                <div *ngIf="changePassword.submitted && confirmPassword.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="confirmPassword.errors.required">Confirm Password is required</div>
                                    <div *ngIf="confirmPassword.errors.mustMatch">Passwords must match</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3" *ngIf="show">
                        <button class="btn btn-success mr-1" type="submit">Submit</button>
                    </div>
                    <div class="text-center mt-3" *ngIf="!submitLoader">
                        <div class="alert alert-info" role="alert">Please wait... </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="copyright py-2 text-center text-white">
        <div class="container"><small>© 2020 <a href="https://natsoftconsulting.com.au/" target="_blank">Natsoft
              Consulting</a> - All Rights Reserved</small></div>
      </div>
</div>