import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { apiEndPoints } from 'src/constants/api-constants';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  url = environment.serverUrl;
  apiEndPoints = apiEndPoints;
  constructor(private authService: AuthService, private router: Router, private spinner: NgxSpinnerService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if ((req.url !== `${this.url}${this.apiEndPoints.getAccessToken}`)
      && (req.url !== `${this.url}${this.apiEndPoints.forgotPassword}`)
      && (req.url !== `${this.url}${this.apiEndPoints.resetPassword}`)) {
      req = req.clone({
        headers: req.headers.set(`Authorization`, `Bearer ${this.authService.getToken()}`)
      });
    }
    return next.handle(req).pipe(
      catchError(err => {
        this.spinner.hide();
        console.log(err.status);
        if (err.error.error !== 'invalid_token' && err.error.error !== 'invalid_refresh_token') {
          Swal.fire('Error!', err.error.error_description, 'error');
        }
        // tslint:disable-next-line: no-conditional-assignment
        else if (err.error.error === 'invalid_token') {
          this.authService.refreshToken();
        }
        // tslint:disable-next-line: no-conditional-assignment
        else if (err.error.error === 'invalid_refresh_token') {
          localStorage.clear();
          this.authService.logoutAfterExpiry();
        }
        return throwError(err);
      }));
  }
}
