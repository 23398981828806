import { Component, OnInit } from '@angular/core';
import { NgForm, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {


  model: any = {};
  userForm: any;
  isSubmitted: boolean;
  fieldTextType = false;
  show: boolean = true;
  submitLoader: boolean = true;


  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.userForm = this.fb.group({
      userName: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('accessToken')) {
      this.router.navigate(['/']);
    }
    this.toggleFieldTextType();

  }
  goToChangePassword() {
    this.isSubmitted = true;
    if (this.userForm.invalid) {
      return;
    }
    console.log(this.userForm.valid);
    this.show = false;
    this.submitLoader = false;

    if (this.userForm.valid && this.isSubmitted) {
      const formData = {
        username: this.userForm.value.userName ? this.userForm.value.userName : '',
        email: this.userForm.value.userEmail ? this.userForm.value.userEmail : ''
      };
      this.authService.forgotPassword(formData).subscribe(res => {
        this.show = res;

        if (this.show) {
          this.submitLoader = true;
        }

      });
    }
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
    if (this.fieldTextType) {
      this.userForm.controls['userName'].setValidators([
        Validators.required,
      ]);
      this.userForm.controls['userName'].updateValueAndValidity();
      this.userForm.controls['userEmail'].clearValidators();
      this.userForm.controls['userEmail'].updateValueAndValidity();


    } else {
      this.userForm.controls['userEmail'].setValidators([
        Validators.required, Validators.email
      ]);
      this.userForm.controls['userEmail'].updateValueAndValidity();
      this.userForm.controls['userName'].clearValidators();
      this.userForm.controls['userName'].updateValueAndValidity();
    }
  }
}
