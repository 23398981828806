import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  fieldTextType: boolean;
  fieldTextType1: boolean;
  show: boolean = true;
  submitLoader: boolean = true;
  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
  }
  onResetPassword(form: NgForm) {
    console.log(form.value);
    if (form.invalid) {
      return;
    }
    this.show = false;
    this.submitLoader = false;
    console.log(form.value);
    this.authService.resetOldPassword(form.value.email, form.value.otp, form.value.password).subscribe(res => {
      this.show = res;

      if (this.show) {
        this.submitLoader = true;
      }

    });

  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextType1() {
    this.fieldTextType1 = !this.fieldTextType1;
  }
}
