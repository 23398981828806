import { Component, OnInit } from '@angular/core';
import { AuthService } from './components/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router) {

  }
  ngOnInit(): void {
    this.authService.autoAuthUser();

    if (this.authService.getIsAuth()) {
      if (localStorage.getItem('firstLogin') == 'true' && localStorage.getItem('registration') == 'false') {
        this.router.navigate(['/change-password']);
      }
      if (localStorage.getItem('registration') == 'false' && localStorage.getItem('firstLogin') == 'false') {
        this.router.navigate(['/company-registration']);

      }

    }
  }
}
