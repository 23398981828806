<div class="bg d-flex flex-column min-vh-100">
    <div class="login-form-holder my-auto">
        <div class="text-center mb-3">
            <img src="../../../assets/images/logo.png" class="rounded mx-auto d-block" alt="" routerLink="/"
                style="cursor: pointer;">
        </div>

        <div class="card card-round col-sm-12 ">
            <h3 class="mb-2 text-muted  mx-auto"> <span class="badge badge-warning rounded-0 text-dark">Reset
                    Password</span>
            </h3>
            <div class="card-body">
                <form name="form" (submit)="resetPassword.form.valid && onResetPassword(resetPassword)"
                    #resetPassword="ngForm" [appMustMatch]="['password', 'confirmPassword']" novalidate>
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" name="email" [(ngModel)]="model.email"
                                #email="ngModel" [ngClass]="{ 'is-invalid': resetPassword.submitted && email.invalid }"
                                required email />
                            <div *ngIf="resetPassword.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">Email is required</div>
                                <div *ngIf="email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label>OTP</label>
                            <input type="text" name="otp" class="form-control" [(ngModel)]="model.otp" #otp="ngModel"
                                [ngClass]="{ 'is-invalid': resetPassword.submitted && otp.invalid }" required>
                            <div *ngIf="resetPassword.submitted && otp.invalid" class="invalid-feedback">
                                <div *ngIf="otp.errors.required">OTP is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col">
                            <label>New Password</label>
                            <div class="input-group  ml-auto">
                                <input [type]="fieldTextType ? 'text' : 'password'" name="password" class="form-control"
                                    [(ngModel)]="model.password" #password="ngModel"
                                    [ngClass]="{ 'is-invalid': (resetPassword.submitted || resetPassword.touched) && password.invalid }"    minlength="6" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$" required>
                                <span class="input-group-append" (click)="toggleFieldTextType()">
                                    <div class="input-group-text bg-transparent"> <i class="fa" [ngClass]="{
                                      'fa-eye-slash': !fieldTextType,
                                      'fa-eye': fieldTextType
                                    }"></i>
                                    </div>
                                </span>

                                <div *ngIf="(resetPassword.submitted || resetPassword.touched) && password.invalid" class="invalid-feedback">
                                    <div *ngIf="password.errors.required">Password is required</div>
                                    <div *ngIf="password.errors.minlength || password.errors.pattern">Password must be
                                        at Minimum six characters, at least one uppercase letter, one lowercase letter,
                                        one number and one special character: </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col">
                            <label>Confirm New Password</label>
                            <div class="input-group  ml-auto">
                                <input [type]="fieldTextType1 ? 'text' : 'password'" name="confirmPassword"
                                    class="form-control" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel"
                                    [ngClass]="{ 'is-invalid': resetPassword.submitted && confirmPassword.invalid }"
                                    required>
                                <span class="input-group-append" (click)="toggleFieldTextType1()">
                                    <div class="input-group-text bg-transparent"> <i class="fa" [ngClass]="{
                                      'fa-eye-slash': !fieldTextType1,
                                      'fa-eye': fieldTextType1
                                    }"></i>
                                    </div>
                                </span>
                                <div *ngIf="resetPassword.submitted && confirmPassword.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="confirmPassword.errors.required">Confirm Password is required</div>
                                    <div *ngIf="confirmPassword.errors.mustMatch">Passwords must match</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt-3" *ngIf="show">
                        <button class="btn btn-success mr-1" type="submit">Submit</button>
                    </div>
                    <div class="text-center mt-3" *ngIf="!submitLoader">
                        <div class="alert alert-info" role="alert">Please wait... </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="copyright py-2 text-center text-white">
        <div class="container"><small>© 2020 <a href="https://natsoftconsulting.com.au/" target="_blank">Natsoft
              Consulting</a> - All Rights Reserved</small></div>
      </div>
</div>