import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SideNavComponent } from './components/sidenav/sidenav.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { AuthGuard } from './components/auth/auth.guard';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent },
  {
    path: 'company-registration',
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/auth/company-registration/company-registration.module')
      .then(m => m.CompanyRegistrationModule)
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    component: ChangePasswordComponent
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: SideNavComponent,
    children: [
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

      {
        path: 'dashboard',
        loadChildren: () => import('./components/sidenav/pages/dashboard/dashboard.module')
          .then(m => m.DashboardModule)
      },
      {
        path: 'sowing',
        loadChildren: () => import('./components/sidenav/pages/sowing/sowing.module')
          .then(m => m.SowingModule)
      },

      {
        path: 'flowering',
        loadChildren: () => import('./components/sidenav/pages/flowering/flowering.module')
          .then(m => m.FloweringModule)
      },
      {
        path: 'harvesting',
        loadChildren: () => import('./components/sidenav/pages/harvesting/harvesting.module')
          .then(m => m.HarvestingModule)
      },

      {
        path: 'inspection',
        loadChildren: () => import('./components/sidenav/pages/inspections/inspections.module')
          .then(m => m.InspectionsModule)
      },
      {
        path: 'test-results',
        loadChildren: () => import('./components/sidenav/pages/test-results/test-results.module')
          .then(m => m.TestResultsModule)
      },
      {
        path: 'update-profile',
        loadChildren: () => import('./components/sidenav/pages/update-profile/update-profile.module')
          .then(m => m.UpdateProfileModule)
      },

      {
        path: 'report-problem',
        loadChildren: () => import('./components/sidenav/pages/report-problem/report-problem.module')
          .then(m => m.ReportProblemModule)
      },



    ],
  },
  {
    path: 'about',
    loadChildren: () => import('./components/auth/about/about.module')
      .then(m => m.AboutModule)
  },



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
